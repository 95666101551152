/*
 * @Author: xr
 * @Date: 2022-02-18 13:42:06
 * @LastEditors: xr
 * @LastEditTime: 2022-02-21 09:14:50
 * @FilePath: \postStation-office\src\api\login.js
 */
import request from '../utils/request'

// 获取验证码
export const getQR = query => {
  return request({
    url: '/sys/randomImage/1',
    method: 'get',
    params: query
  })
}

// 登录接口
export const login = query => {
  return request({
    url: '/sys/login',
    method: 'post',
    data: query
  })
}

// 注册接口
export const register = query => {
  return request({
    url: '/sys/website/register/add',
    method: 'post',
    data: query
  })
}

