<!--
 * @Author: xr
 * @Date: 2022-01-26 09:21:31
 * @LastEditors: xr
 * @LastEditTime: 2022-03-07 17:19:19
 * @FilePath: \postStation-office\src\views\home.vue
-->
<template>
  <div>
    <!-- 导航 -->
    <nav-box />
    <menu-bar/>
    <div class="app-content">
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
    <!-- 底部信息-->
    <footer-box />
  </div>
</template>
<script>

import menuBar from '@comp/layout/menuBar'
import footerBox from '@comp/layout/footer'
import navBox from '@comp/layout/nav'

export default {
  name: 'Home',
  data() {
    return {
      station: true
    }
  },
  components: {
    menuBar,
    footerBox,
    // linkBox,
    navBox
  }
}
</script>
<style>
.app-content {
  min-width:1010px;
  min-height: calc(100vh - 196px);
}
@import "~@assets/css/base.css";
</style>
