<!--
 * @Author: xr
 * @Date: 2022-01-26 12:04:32
 * @LastEditors: xr
 * @LastEditTime: 2022-03-11 09:43:17
 * @FilePath: \postStation-office\src\components\layout\menuBar.vue
-->

<template>
  <div style="background-color: #0e5298;">
    <a-row class="banner" type="flex">
      <span class="logo-box"/>
      <!-- 主站菜单 -->
      <ul v-show="!stationId" class="menu">
        <li :class="($route.path === '/index'?'menu-active ':'')+'right_border'">
          <router-link to="/">首页</router-link>
        </li>
        <li :class="($route.path === '/index'?'menu-active ':'')+'right_border'">
          驿站列表
          <ul class="menu1" style="padding: 20px 0; font-size: 20px;width: 200px;"  v-for="item in data" :key="item.id" >
            <li @click="stationClick(item.stationId, item.city)" style="width: 200px;text-align: center;">{{item.city}}</li>
          </ul>
        </li>
        <li :class="($route.path === '/news'?'menu-active ':'')+'right_border'">
          <router-link to="/news">新闻资讯</router-link>
        </li>
        <li :class="($route.path === '/resource'?'menu-active ':'')+'right_border'">
          <router-link to="/resource">项目库</router-link>
        </li>
        <li :class="($route.path === '/demandType'?'menu-active ':'')+'right_border'">
          <router-link to="/demandType">需求库</router-link>
        </li>
        <li :class="($route.path === '/professional'?'menu-active ':'')+'right_border'">
          <router-link to="/professional">专家库</router-link>
        </li>
        <li :class="($route.path === '/download'?'menu-active ':'')+'right_border'">
          <router-link to="/download">下载中心</router-link>
        </li>
        <li :class="($route.path === '/customService'?'menu-active ':'')+'right_border'">
          <router-link to="/customService">客服中心</router-link>
        </li>
        <li :class="($route.path === '/talent-list'?'menu-active ':'')+'right_border'">
          <router-link to="/talent-list">人才招聘</router-link>
        </li>
        <li :class="$route.path === '/us'?'menu-active ':''">
          <router-link to="/us">关于我们</router-link>
        </li>
      </ul>
      <!-- 子站菜单 -->
      <ul v-show="stationId">
        <li :class="($route.path === '/index'?'menu-active ':'')+'right_border'">
          <router-link :to="'/index?stationId='+stationId">首页</router-link>
        </li>
        <li :class="($route.path === '/news'?'menu-active ':'')+'right_border'">
          <router-link :to="'/news?stationId='+stationId">新闻资讯</router-link>
        </li>
        <li :class="($route.path === '/regular'?'menu-active ':'')+'right_border'">
          <router-link :to="'/regular?stationId='+stationId">政策法规</router-link>
        </li>
         <li :class="($route.path === '/investment'?'menu-active ':'')+'right_border'">
          <router-link :to="'/investment?stationId='+stationId">招商政策</router-link>
        </li>
        <li :class="($route.path === '/talent-list'?'menu-active ':'') + 'right_border'">
          <router-link :to="'/talent-list?stationId='+stationId">人才招聘</router-link>
        </li>
        <li :class="$route.path === '/backMain'?'menu-active ':''">
          <span @click="backMain">返回主站</span>
        </li>
      </ul>
    </a-row>
  </div>
</template>
<script>
import { Station } from '@/mixins/station'
import { getStation } from '@/api/index'

export default {
  name: 'MenuBar',
  mixins: [Station],
  data() {
    return {
      meet: [],
      data: []

    }
  },
  methods: {
    /**
     * @description:跳转驿站
     * @param {*} id
     */
    stationClick(id, name) {
      const routerJump = this.$router.resolve({ path: '/index', query: { stationId: id, stationName: name }})
      window.open(routerJump.href, '_blank')
    },
    /**
     * @description:跳转到主站
     * @param {*} id
     */
    backMain() {
      const routerJump = this.$router.resolve({ path: '/index' })
      location.href = routerJump.href
    }
  },
  mounted() {
    getStation().then(res => {
      const leng = res.result// 接res传过来的数据
      // 判断驿站个数
      for (var n = 0; n < leng.length; n++) {
        this.count = 0
        const cityname = leng[n].cityname
        for (var i = 0; i < leng.length; i++) {
          if (cityname === leng[i].cityname) {
            this.count++
          }
        }
        // 并获取那个地区的驿站信息
        this.meet.push({
          province: leng[n].pname,
          city: leng[n].stationName,
          region: leng[n].cityname,
          sumArea: this.count.toString(),
          stationId: leng[n].id
        })
      }
      // 给data添加数据
      this.meet.forEach(n => {
        this.data.push(
          {
            province: n.province,
            city: n.city,
            region: n.region,
            sumArea: n.sumArea,
            stationId: n.stationId
          }
        )
      })
      console.log(this.data)
    })
  },
  created() {
    this.getStationId()
  }
}
</script>
<style scoped>
.menu,.menu1{
  list-style:none;
  background-color: #0e5298;
  z-index: 999;

}
.menu li{
  float:left;
}
.menu li:hover .menu1{
  display:block;
}
.menu1 {
  display: none;
}
</style>
