<!--
 * @Author: xr
 * @Date: 2022-01-26 13:48:00
 * @LastEditors: xr
 * @LastEditTime: 2022-03-11 10:54:59
 * @FilePath: \postStation-office\src\components\layout\footer.vue
-->
<template>
  <div>
    <div class="footer-main" >
      <div class="contactUs">
        <span v-for="(item, index) in linkList" :key="item.index">
          <router-link v-if="stationId !== ''" :to="{path: item.link, query: {stationId:stationId}}" :key="index">{{ item.name }}</router-link>
          <router-link v-else :to="{path: item.link}" :key="item.link">{{ item.name }}</router-link>
          <a-divider
            v-show="index===0"
            type="vertical"
            style="background: #333"
          />
        </span>
      </div>
      <div class="footer-content">
        <div>
          <a-row type="flex" justify="space-around" >
            <a-col :span="4">
              <div class="ewmleft">
                <img class="gzh" src="@assets/img/gzh.jpg">
                <div class="img-title">
                  <img style="width: 20px" src="@assets/img/wechat.png">
                  <span>微信公众号</span>
                </div>
              </div>
            </a-col>
            <a-col :span="11">
              <div class="infobott">
                <p>业务洽谈：18758878842</p>
                <p>企业邮箱：info@zjuhome.com</p>
                <p>地&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;址：浙江省杭州市西湖区古墩路99号浙商财富大楼11楼</p>
                <p>版权所有：杭州友驿企业管理咨询有限公司 </p>
                <!--          <p>技术支持：<a class="fs18" href="http://ccjiuhong.com/" target="_blank">长春久弘信息技术有限公司 </a></p>-->
                <!--          <p style="cursor: pointer" @click="beian">{{ ICP }}</p>-->
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      </div>
    <a-divider style="width: 90%; margin: 0px 0px 10px 0px"/>
    <div style="text-align: center; padding-bottom: 1%">
      <span style="cursor: pointer; color:  rgb(14, 82, 152);" @click="beian">{{ ICP }} </span>
    </div>
  </div>

</template>
<script>
import { Station } from '@/mixins/station'

export default {
  name: 'Fotter',
  mixins: [Station],
  data() {
    return {
      // 路由
      linkList: [
        {
          name: '关于我们',
          link: '/us'
        },
        // {
        //   name: '网站声明',
        //   link: '/declaration'
        // },
        {
          name: '联系我们',
          link: '/customService'
        }
      ],
      // count: 15205,
      ICP: '浙ICP备17053947号-1'
    }
  },
  methods: {
    /**
     * @description:跳转
     */
    beian() {
      window.open('https://beian.miit.gov.cn')
    }
  },
  created() {
    this.getStationId()
  }
}
</script>
<style lang="scss" scoped>
.link{
  color:#0051a4;
}
.contactUs {
  width: 1010px;
  margin: 10px auto;
  text-align: center;
  background-color: #f6f6f6;
  padding: 10px 0;
}
.infobott {
  text-align: left;
  vertical-align: top;
  p {
    font-size: 15px;
    color: #555555;
  }
}
.ewmleft{
  margin: 0 auto;
  text-align: center;
  .gzh {
    width: 120px;
  }
  .img-title{
    margin: 10px auto;
  }
}

.footer-main {

}
.footer-content {
  width: 1010px;
  margin: 10px auto;
  background-image: linear-gradient(to right, #d3e4f4, #e5f2fb);
  padding-top: 20px;
}

</style>
