<!--
 * @Author: xr
 * @Date: 2022-01-26 17:06:47
 * @LastEditors: xr
 * @LastEditTime: 2022-03-07 11:12:51
 * @FilePath: \postStation-office\src\components\layout\nav.vue
-->
<template>
  <div style="background: #eee">
    <a-row class="login-info" type="flex" align="middle" justify="space-between">
      <div>
        <span style="vertical-align:middle">Hi~，欢迎进入{{ title }}！</span>
        <img src="../../assets/img/firework.png" style="width:22px;" alt="烟花">
      </div>
      <!-- <a-form class="ruleForm" :model="form" layout="inline"> -->
        <!-- <a-form-item label="用户名">
          <a-input size="small" v-model="form.username" style="width: 100px" />
        </a-form-item>
        <a-form-item label="密码">
          <a-input size="small" v-model="form.password" style="width: 100px" />
        </a-form-item>
        <a-form-item label="验证码">
          <a-input size="small" v-model="form.captcha" style="width: 100px" />
        </a-form-item> -->
        <a-row style="padding: 4px 0px" >
          <!-- <a-button v-show="click" @click="getCode" type="link" size="small">获取验证码</a-button> -->
          <img class="qrCode" @click="getCode" :src="qrSrc" alt="" v-show="!click" >
          <a-button size="small" @click="handleLogin">点击登录</a-button>
          <a-button @click="register" type="link" size="small">免费注册</a-button>
          <a-button @click="retrievePassword" type="link" size="small">找回密码</a-button>
          <span @click="handle" style="cursor:pointer;margin-left:10px;"><a-icon type="search"/>全站检索</span>
        </a-row>
      <!-- </a-form> -->
    </a-row>
  </div>
</template>
<script>
import { getQR } from '@/api/login'
import { Station } from '@/mixins/station'
export default {
  mixins: [Station],
  data() {
    return {
      form: {},
      qrSrc: '',
      click: true,
      title: '浙大校友求是驿站'
    }
  },
  methods: {
    /**
     * @description:跳转注册
     */
    register() {
      this.getStationId()
      let tempString = ''
      if (this.stationId) {
        tempString = '?stationId=' + this.stationId
      }
      this.$router.push('/register' + tempString)
    },
    /**
     * @description:获取二维码
     */
    getCode() {
      getQR().then((res) => {
        this.qrSrc = res.result
        this.click = false
      })
    },
    /**
     * @description:获取二维码
    */
    handle() {
      let stationId = ''
      // 判断是否为子站
      if (window.location.search !== '') {
        stationId = window.location.search.split('=')[1]
        this.$router.push({ name: 'search', query: { stationId: stationId }})
      } else {
        this.$router.push('/search')
      }
    },
    handleLogin() {
      // 跳转登录
      window.open('/admin/', '_blank')
    },
    /**
     * @description:跳转注册
     */
    retrievePassword() {
      this.getStationId()
      let tempString = ''
      if (this.stationId) {
        tempString = '?stationId=' + this.stationId
      }
      this.$router.push('/retrieve-password' + tempString)
    }
  },
  created() {
    // 判断是否为子站
    this.getStationId()
    if (this.stationName) {
      document.title = this.stationName
      this.title = this.stationName
    }
  }
}
</script>
<style lang="scss">
.qrCode{
  height:28px;
  margin-top: -3px;
  margin-right:8px;
}
.login-info {
  width: 1010px;
  margin: 0 auto;
  background: #eee;
  height: 32px;
  .ruleForm {
    .ant-form-item-label,
    .ant-form-item-control {
      line-height: 31px;
    }
  }
}
</style>
